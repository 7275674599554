'use strict'

// Users table mixin
// Manages the table of users in the admin panel (for both admins and sub-account-enabled users)

const $ = require('jquery')
const { setupImprovedDataTable } = require('../dataTables')
const { encode, ellipsis } = require('../utils')

// allowCreate: Whether a new user can be created
module.exports = ({ allowCreate, isReseller }) => $(() => {
  setupImprovedDataTable($('#userTable'), {
    columns: [
      { title: 'Email/Username', data: 'email', className: 'break-word', render: (email, type, { username, approved, blockedForPaymentReview, quarantined }) => encode(email === username ? email : `${email} (${username})`) + (!approved ? ' <i title="Pending approval" class="fa fa-hourglass-half"></i>' : '') + (blockedForPaymentReview ? ' <i title="Blocked for payment review" class="text-danger fa fa-lock"></i>' : '') + (quarantined ? ' <i title="Quarantined" class="fa fa-exclamation-circle"></i>' : '') },
      ...(window.loggedInUser.isAdmin || isReseller || window.loggedInUser.isCustomerServiceRep) ? [
        { title: 'Owner/Domain', data: 'ownerUsername', defaultContent: '', render: (ownerUsername, type, { ownerId, domainName }) => (ownerId ? `<a href="/transactions/${ownerId}" title="${encode(ownerUsername)}">${encode(ellipsis(ownerUsername, 10))}</a><br>` : '') + `<small title="${encode(domainName)}">${encode(ellipsis(domainName, 14))}</small>` }
      ] : [],
      ...(window.loggedInUser.isAdmin || window.loggedInUser.isCustomerServiceRep) ? [
        { title: 'Rank', data: 'rankDisplayName', render: $.fn.dataTable.render.text(), sortable: false },
        { title: 'Group', data: 'group', render: $.fn.dataTable.render.text() },
        ...window.loggedInUser.isCustomerServiceRep ? [
          { title: 'Line', data: 'lineId', defaultContent: '', render: (lineId, type, { currentServiceString, fakeNumber }) => lineId ? `<a href="/agentLineManagement/${lineId}">Line ${lineId}</a><br><small>${encode(currentServiceString)}</small>` + (fakeNumber ? `<br><small class="text-danger"><strong>FAKE:</strong> ${encode(fakeNumber)}</small>` : '') : '' }
        ] : [
          { title: 'Line', data: 'lineName', defaultContent: '', className: 'break-word', render: (lineName, type, { lineId, currentServiceString, fakeNumber }) => lineId ? `<a href="/admin/edit-line/${lineId}" title="${encode(lineName)}">${encode(ellipsis(lineName, 10))}</a><br><small>${encode(currentServiceString)}</small>` + (fakeNumber ? `<br><small class="text-danger"><strong>FAKE:</strong> ${encode(fakeNumber)}</small>` : '') : '' }
        ]
      ] : [
        { title: 'Phone Number', data: 'phoneNumber', defaultContent: '', render: $.fn.dataTable.render.text(), sortable: false }
      ],
      { title: 'Balance', data: 'balance', defaultContent: '0.00', className: 'text-right', render: (balance, type, { usdBalance }) => `<i class="fa fa-diamond"></i> ${balance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` + (usdBalance != null ? `<br><small class="${usdBalance < 0 ? 'text-danger' : ''}">$${usdBalance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</small>` : '') },
      { title: 'Reg. Date', data: 'createdAt', defaultSort: 'desc', render: $.fn.dataTable.render.date() }
    ],
    createdRow: (row, data, dataIndex) => {
      // Dim suspended users' rows
      if (data.suspend) $(row).addClass('text-muted')
      if (data.quarantined) $(row).addClass('text-danger')
    },
    serverSide: true,
    ajax: '/admin-users/dtAjax',
    buttons: [{
      text: 'Create New User',
      href: '/admin/create-user',
      className: 'btn-primary',
      disabled: !allowCreate
    }, $.fn.dataTable.defaults.buttons, {
      text: '$diamond',
      tooltip: 'Transactions',
      href: data => `/transactions/${data.id}`,
      className: 'btn-info',
      extend: 'selected',
      isRowClickHandler: true
    }, {
      text: '$pencil',
      tooltip: 'Edit',
      href: data => `/admin/edit-user/${data.id}`,
      className: 'btn-primary',
      extend: 'selected'
    }, {
      text: '$book',
      tooltip: 'SMS/Call History',
      href: data => `/services/${data.id}`,
      className: 'btn-warning',
      extend: 'selected'
    }, ...window.loggedInUser.isAdmin ? [{
      text: '$trash',
      tooltip: 'Delete',
      href: data => `/admin/delete-user/${data.id}`,
      className: 'btn-danger',
      extend: 'selected',
      disabled: data => data.id === window.loggedInUser.id,
      action: (e, dt, node, button, row) => {
        const data = row.data()
        if (row.ownerUsername && row.balance) {
          return window.confirm(`User "${data.username}" will be deleted and their ${data.balance} credits will be transfered back to the owner, are you sure?`)
        } else {
          return window.confirm(`Are you sure that you want to delete user "${data.username}"?`)
        }
      }
    }] : []]
  })
})
