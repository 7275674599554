<script>
import { onMount } from 'svelte'

export let tiers = []
export let usecases = {}
export let globalBasePricing
export let serviceTierAssignments = []
export let hasPreventNegative


let selectpickers

function createNewTableData () {
  return {serviceId: '_', tierId: ''}
}

function createTableRow (idx) {
  serviceTierAssignments = serviceTierAssignments.toSpliced(idx, 0, createNewTableData())
}

function removeTableRow (idx) {
  serviceTierAssignments = serviceTierAssignments.toSpliced(idx, 1)
}

function rehydrateSelectpicker (refresh) {
  //such hydration...
  setTimeout(() => {
    selectpickers = window.$('select.selectpicker.service-field')
    refresh ? selectpickers.selectpicker('refresh') : selectpickers.selectpicker()
  }, 0)
}

function removeDeletedUsecasePricing () {
  serviceTierAssignments = serviceTierAssignments.filter(el => !!usecases[el.serviceId] ||el.serviceId === '_')
  rehydrateSelectpicker(true)
}

onMount(rehydrateSelectpicker)
$: serviceTierAssignments, rehydrateSelectpicker(true)
$: usecases, removeDeletedUsecasePricing()
</script>


<h3>Service Tier Assignments</h3>
<small class="form-help text-muted">Any service without a service tier is treated as being in the default service tier.</small>

<div class="service-tier-editor clearfix">
  <table class="table">
  <thead>
  <tr>
  <th>Service</th>
  <th>Service Tier</th>
  {#if globalBasePricing}
    <th class="text-center">Base Cost + Markup = Total Service Cost</th>
  {/if}
  <th style="width: 120px"></th>
  </tr>
  </thead>
  <tbody>
  {#each serviceTierAssignments as row, i (row)}
    <tr>
      <td>
        <select class="form-control selectpicker service-field" title="Select Service" data-live-search="true" bind:value={row.serviceId}>
          <option hidden></option>
          {#each Object.entries(usecases) as [id, {name}]}
            <option class="usecases-option" data-subtext="" value={id} selected={row.serviceId === id}>{name}</option>
          {/each}
        </select>
      </td>
      <td>
        <select class="tier-field form-control" bind:value={row.tierId}>
          {#each tiers as {name, id, isDefaultTier}}
            {#if !isDefaultTier}
              <option value={id} selected={row.tierId === id} data-id={id}>{name}</option>
            {/if}
          {/each}
        </select>
      </td>
      {#if globalBasePricing}
        {@const tierPrice = (tiers.find(tier => tier.id === row.tierId) || {}).price}
        {@const basePrice = globalBasePricing[(usecases[row.serviceId] || {}).nameBasedId]}
        {@const margin = tierPrice - basePrice}
        <td class="text-center"><span class="default-pricing h5">
          <span class="ml-3">{basePrice ? basePrice.toFixed(2) : '?'} <i class="fa fa-diamond"/></span>
          <span class="ml-2">+</span>
          {#if margin < 0 && hasPreventNegative && tiers.find(el => el.id === row.tierId).preventNegative}
            <span class="ml-2">
              <span class="text-muted strikethrough">
                {typeof margin === 'number' && !isNaN(margin) ? margin.toFixed(2) : '?'}
              </span>
              0
              <i class="fa fa-diamond"/>
            </span>
          {:else}
            <span class="ml-2" class:text-danger={typeof margin === 'number' && !isNaN(margin) && margin < 0}>{typeof margin === 'number' && !isNaN(margin) ? margin.toFixed(2) : '?'} <i class="fa fa-diamond"/></span>
          {/if}
          <span class="ml-2">=</span>
          {#if margin <= 0 && hasPreventNegative && tiers.find(el => el.id === row.tierId).preventNegative}
          <span class="mr-3 ">
            <span class="text-muted strikethrough">
              {tierPrice || '?'}
            </span> 
            {basePrice || '?'}
            <i class="fa fa-diamond"/>
          </span>
        {:else}
        <span class="mr-3">{tierPrice || '?'} <i class="fa fa-diamond"/></span>
        {/if}
        </span></td>
      {/if}
      <td>
        <button type="button" title="Add" class="pull-right add-btn btn btn-outline-info" on:click={() => createTableRow(i)}><i class="fa fa-plus"></i></button>
        <button type="button" title="Remove" class="remove-btn btn btn-outline-danger" on:click={() => removeTableRow(i)}><i class="fa fa-minus"></i></button>
      </td>
    </tr>
  {/each}

  </tbody>
  </table>
  <button type="button" title="Add" class="pull-right add-btn btn btn-outline-info" on:click={() => serviceTierAssignments = [...serviceTierAssignments, createNewTableData()]}><i class="fa fa-plus"></i> Add row</button>
  </div>



<style>
.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  border-color: inherit;
  
  -webkit-transform:rotate(-15deg);
  -moz-transform:rotate(-15deg);
  -ms-transform:rotate(-15deg);
  -o-transform:rotate(-15deg);
  transform:rotate(-15deg);
}
</style>