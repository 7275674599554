'use strict'

const $ = require('jquery')

$(() => {
/*   function sortSelected () {
  } */
  const $serviceSelect = $('#usecases')
  const $zipcodeSelect = $('#zipcode')
  const $submitButton = $('#get-new-number')
  const $voiceOnlyCheckbox = $('#voiceOnly')

  function updateAvailableServices () {
    const usecases = $('#usecases').val()
    const zipcode = $('#zipcode').val()

    $serviceSelect.find('option').prop('disabled', true)
    $zipcodeSelect.find('option').prop('disabled', true)
    $submitButton.prop('disabled', true)
    $voiceOnlyCheckbox.prop('disabled', true)

    $serviceSelect.selectpicker('refresh')
    $zipcodeSelect.selectpicker('refresh')
    $.ajax({
      contentType: 'application/json',
      method: 'POST',
      url: '/services',
      data: JSON.stringify({
        usecases,
        zipcode,
        voiceOnly: $voiceOnlyCheckbox.is(':checked')
      }),
      dataType: 'json'
    }).then(
      (res) => {
        /* res = {
          zipcodes: ['00001', '00002', ...],
          usecases: ['FACEBOOK', 'TWITTER', ...],
          voiceOnly true || false
        } */
        updateSelect($serviceSelect, res.usecases, 'usecases')
        updateSelect($zipcodeSelect, res.zipcodes, 'zip')
        updateCheckbox($voiceOnlyCheckbox, res.voiceOnly)
        $('#price').text(getCurrentPrice(usecases).toFixed(2))
        $submitButton.prop('disabled', usecases.length < 1)
      },
      () => {
        $('#clientSideErrorMessage').text('A connection error has occurred, please reload the page.')
        $('#clientSideError').show()
      }
    )
  }

  function getCurrentPrice (usecases) {
    return usecases.map(service => window.servicePriceList[service]).reduce((prev, curr) => prev + curr, 0)
  }

  function updateSelect ($select, availableEntries, mode, icons = {}) {
    const useIcons = icons.enabled || icons.disabled
    $select.find('option').data('subtext', 'currently unavailable')
    if (useIcons) $select.find('option').data('icon', icons.disabled ? 'fa fa-' + icons.disabled : '')

    // Never show currently selected items as disabled, to allow deselecting them
    // "currently unavailable" will still be shown
    $select.find('option:selected').prop('disabled', false)

    availableEntries.forEach(entry => {
      if (mode === 'usecases') {
        $select.find('.usecase-' + entry).prop('disabled', false).data('subtext', '')
        if (useIcons) $select.find('.usecase-' + entry).data('icon', icons.enabled ? 'fa fa-' + icons.enabled : '')
      }
      if (mode === 'zip') {
        $select.find('.zip-' + entry).prop('disabled', false).data('subtext', '')
        if (availableEntries.length > 0) $select.find('.zip-ANY').prop('disabled', false).data('subtext', '')
      }
    })
    $select.selectpicker('refresh')
  }

  function updateCheckbox ($checkbox, isAvailable) {
    if (isAvailable) {
      $checkbox.prop('disabled', false)
    } else {
      $checkbox.prop('disabled', !$checkbox.is(':checked'))
    }
  }

  updateAvailableServices()

  $serviceSelect.change(updateAvailableServices)
  $zipcodeSelect.change(updateAvailableServices)
  $voiceOnlyCheckbox.on('checked unchecked click', updateAvailableServices)
})
