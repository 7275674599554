'use strict'

const $ = require('jquery')

module.exports = ({ domainId }) => $(() => {
  $('.add-my-ip-btn').click(() => {
    const myIp = $('.add-my-ip-btn').attr('data-my-ip')
    const ipVals = $('#ipRestriction').val().split(/,/).map(x => x.trim()).filter(x => x)
    if (!ipVals.includes(myIp)) ipVals.push(myIp)
    $('#ipRestriction').val(ipVals.join(', '))
    return false
  })

  $('.submit-btn').click(() => {
    const myIp = $('.add-my-ip-btn').attr('data-my-ip')
    const ipVals = $('#ipRestriction').val().split(/,/).map(x => x.trim()).filter(x => x)
    if (ipVals.length && !ipVals.includes(myIp)) {
      if (!window.confirm(`You have set an IP restriction but not included your own IP address (${myIp}). Are you sure you want to continue?`)) return false
    }
    return true
  })

  const updatePageLink = () => {
    const domainName = $('#domainName').val()
    $('.domain-help').toggle(!!domainName)
    $('.domain-help a').attr('href', `https://${domainName}`)
    $('.domain-help a').text(`https://${domainName}`)
  }

  updatePageLink()
  $('#domainName').change(updatePageLink)

  const updateDnsSetup = () => {
    const hostname = $('#domainName').val().replace(/:\d+$/, '')
    const cname = $('#cname').val()

    $('.dns-setup .card-body > span').hide()
    if (!hostname || !cname) {
      $('.dns-no-cname').show()
      return
    }

    $('.dns-checking').show()

    $('.dns-domain-name').text(hostname)
    $('.dns-cname').text(cname)

    $.post('/checkDns', { hostname }).done(result => {
      $('.dns-setup .card-body > span').hide()
      if (result.error || !result.records.length) {
        $('.dns-actual-settings').text('None')
        $('.dns-not-ok').show()
      } else if (result.records.length === 1 && result.records[0].type === 'CNAME' && result.records[0].value.replace(/\.$/, '') === cname) {
        $('.dns-ok').show()
      } else {
        $('.dns-actual-settings').text(result.records.map(({ type, address, value }) => `${type} ${address || value}`).join(', '))
        $('.dns-not-ok').show()
      }
    }).fail(e => {
      console.error(`Failed to check DNS`, hostname, e)
      $('.dns-setup .card-body > span').hide()
      $('.dns-error').show()
    })
  }

  updateDnsSetup()
  $('#domainName, #cname').change(updateDnsSetup)

  let needNavbarRefresh = false
  const updateNavbarPreview = () => {
    const logoUrl = $('#logoUrl').val()
    $('.navbar-preview .navbar-brand span').toggle(!logoUrl).text($('#brandName').val())
    $('.navbar-preview .navbar-brand img').toggle(!!logoUrl).attr('src', logoUrl)
    $('.navbar-preview .navbar').removeClass('navbar-light navbar-dark').addClass(`navbar-${$('#navbarStyle').val()}`)
    $('.navbar-preview .navbar').css({ backgroundColor: $('#navbarColor').val() })
    $('.navbar-preview .navbar-nav .custom-link').remove()

    const customLinks = $('#navbarLinks').val().split(/\r?\n/).map(link => link.replace(/=.*$/, '').trim()).filter(x => x)

    for (const customLink of customLinks) {
      const $li = $('<li class="nav-item custom-link"><a class="nav-link" href="javascript:;"></a></li>')
      $li.find('a').text(customLink)
      $li.insertBefore('.navbar-preview .navbar-nav .btn-group')
    }
  }

  updateNavbarPreview()
  setInterval(() => {
    if (needNavbarRefresh) {
      needNavbarRefresh = false
      updateNavbarPreview()
    }
  }, 500)
  $('#brandName, #navbarColor, #navbarStyle, #logoUrl, #navbarLinks').on('input change', () => {
    needNavbarRefresh = true
  })

  $('#ownerUsername').typeahead({
    highlight: true
  }, {
    name: 'username',
    display: 'username',
    source: (query, syncResults, asyncResults) => {
      $.get('/userSearch?q=' + encodeURIComponent(query), asyncResults)
    }
  })
  // This fix is required to make the error messages still show because Typeahead would cut the connection between the <input> and the <span class="invalid-feedback">
  $('#ownerUsername').closest('.twitter-typeahead').next('.invalid-feedback').insertAfter('#ownerUsername')

  if ($('#acceptResellerAgreement')) {
    // ensure reseller terms have been scrolled all the way to the bottom before enabling the #acceptResellerTerms button
    $('#resellerAgreementBody').scroll((event) => {
      const termsContainer = $(event.currentTarget)
      if (termsContainer[0].scrollHeight - termsContainer.scrollTop() <= termsContainer.outerHeight() + 3) {
        $('#acceptResellerAgreement').prop('disabled', false)
      }
    })

    $('#acceptResellerAgreement').click((event) => {
      if (!domainId) {
        window.alert('the agreement cant be accepted before creating the domain!')
        return false
      }
      $('#acceptResellerAgreement').hide()
      $.ajax({
        type: 'POST',
        url: '/admin/update-domain/' + domainId + '?_method=PUT',
        data: {
          acceptedResellerAgreement: true
        },
        dataType: 'JSON',
        success: () => {
          $('.agreementInfoText').html('<strong class="text-success"><i class="fa fa-check fa-fw"></i>Reseller agreement has been accepted!</strong>')
        }
      })
    })
  }

  function isCoinAccepted (coin) {
    return $('#acceptedCryptoCoins').val().split('\n').map(x => x.trim().toUpperCase()).filter(x => x).includes(coin)
  }

  function addCoinIfNotAlreadyAccepted (coin) {
    if (!isCoinAccepted(coin)) {
      $('#acceptedCryptoCoins').val($('#acceptedCryptoCoins').val().trim() + '\n' + coin)
    }
  }

  function updateCryptoServedBy () {
    let cryptoServedBy = 'nothing'
    if ($('#coinpaymentsPublicKey').val() && $('#coinpaymentsPrivateKey').val()) cryptoServedBy = 'coinpayments'
    if ($('#coinifyApiKey').val() && $('#coinifyApiSecret').val()) cryptoServedBy = 'coinify'
    if ($('#nowpaymentsApiKey').val()) cryptoServedBy = 'nowpayments'
    if ($('#coinremitterEnabled').prop('checked')) cryptoServedBy = 'coinremitter'
    if ($('#cryptomusMerchantId').val()) cryptoServedBy = 'cryptomus'

    $('.crypto-served-by span').hide()
    $('.crypto-served-by span[data-served-by="' + cryptoServedBy + '"]').show()

    let btcServedBy = 'nothing'
    if (isCoinAccepted('BTC')) {
      btcServedBy = cryptoServedBy
      if ($('#blockonomicsApiKey').val()) btcServedBy = 'blockonomics'
      if ($('#btcpayAccessToken').val()) btcServedBy = 'btcpay'
    }
    $('.btc-served-by span').hide()
    $('.btc-served-by span[data-served-by="' + btcServedBy + '"]').show()

    $('.ltc-served-by-btcpay').toggle($('#btcpaySupportsLtc').prop('checked') && isCoinAccepted('LTC'))
  }

  updateCryptoServedBy()

  $('#acceptedCryptoCoins, #coinpaymentsPublicKey, #coinpaymentsPrivateKey, #coinifyApiKey, #coinifyApiSecret, #nowpaymentsApiKey, #coinremitterEnabled, #cryptomusMerchantId, #blockonomicsApiKey, #btcpayAccessToken').on('input change', function () {
    updateCryptoServedBy()
  })

  $('#blockonomicsApiKey, #btcpayAccessToken').on('input change', function () {
    addCoinIfNotAlreadyAccepted('BTC')
  })

  $('#btcpaySupportsLtc').on('change', function () {
    if ($(this).prop('checked')) {
      addCoinIfNotAlreadyAccepted('LTC')
    }
    updateCryptoServedBy()
  })

  const $advancedCreditPricingTarget = $('#advanced-credit-pricing-component')
  const $basicCreditPricingTarget = $('#basic-credit-pricing-component')

  let defaultServiceTier
  try {
    defaultServiceTier = JSON.parse(window.defaultServiceTier) || {}
  } catch (e) {
    defaultServiceTier = {}
  }

  let basicServiceMargins
  try {
    basicServiceMargins = JSON.parse(window.basicServiceMargins)
  } catch (e) {
    basicServiceMargins = {}
  }

  if ($basicCreditPricingTarget.length) {
    window.BasicCreditPricingInstance = new window.svelteComponents.BasicCreditPricing({
      target: $basicCreditPricingTarget.get(0),
      props: {
        baseTierMarkups: window.baseTierMarkups,
        usecases: window.usecases,
        globalServicePricing: window.globalServicePricing,
        globalServiceTierOptions: window.globalServiceTierOptions,
        globalDefaultServiceTier: window.globalDefaultServiceTier,
        basicServiceMargins
      }
    })
  }

  if ($advancedCreditPricingTarget.length) {
    window.AdvancedCreditPricingInstance = new window.svelteComponents.AdvancedCreditPricing({
      target: $advancedCreditPricingTarget.get(0),
      props: {
        allowEmpty: true,
        hasDefault: false,
        hasPreventNegative: true,
        tiersSource: '#service-tier-options',
        serviceTierAssignmentsSource: '#service-tiers',
        usecases: window.usecases,
        defaultServiceTier: defaultServiceTier,
        globalBasePricing: window.defaultPricing
      }
    })
  }

  window.pageMixins.pricingTable({ allowEmpty: false })
  window.pageMixins.paymentMethodMarkupEditor()
  window.pageMixins.coinremitterCredentialsEditor()
  window.pageMixins.serviceCostTable()
})
