'use strict'

// SMS history table mixin
// Manages the table of SMS on the service history page

const $ = require('jquery')
const { setupImprovedDataTable } = require('../dataTables')
const { encode, postNavigate } = require('../utils')

// userId: ID of the user whose data is displayed
module.exports = ({ userId }) => $(() => {
  const smsTextRenderer = (text, type, { id, claimPrice, isValidService, isSNL }) => {
    if (isValidService || window.loggedInUser.isAdmin || window.loggedInUser.isCustomerServiceRep) {
      return encode(text)
    } else {
      return `
        ${encode(text)}
        <br>
        <br>
        Sorry! It looks like this message wasn't meant for you. But if you’d like to view the message, please
        <br>
        <form class="form-inline" action="/sms/${id}/unlock" method="POST"><button class="btn btn-primary" type="submit">Claim Message (<i class="fa fa-diamond"></i> ${claimPrice.toFixed(2)})</button></form>
        <br>
        If this is a mistake, please contact support for assistance.
      `
    }
  }

  setupImprovedDataTable($('#sms-table'), {
    columns: [
      { title: 'Received at', data: 'timestamp', defaultSort: 'desc', render: $.fn.dataTable.render.date() },
      { title: 'Sender', data: 'phoneNumber', render: $.fn.dataTable.render.text() },
      { title: 'Receiver', data: 'lineNumber', render: $.fn.dataTable.render.text() },
      { title: 'Text', data: 'text', defaultContent: '', className: 'break-word', render: smsTextRenderer },
      ...(window.loggedInUser.isAdmin || window.loggedInUser.isCustomerServiceRep) ? [
        { title: 'Valid Service', data: 'isValidService', render: isValidService => isValidService ? '<span style="color: limegreen;">Yes</span>' : '<span style="color: red;">No</span>' }
      ] : []
    ],
    serverSide: true,
    ajax: `/services/${userId}/sms/dtAjax`,
    buttons: [$.fn.dataTable.defaults.buttons, ...window.loggedInUser.isAdmin ? [{
      text: '$check',
      tooltip: 'Set Valid',
      className: 'btn-success',
      extend: 'selected',
      disabled: data => data.isValidService,
      action: (e, dt, node, button, row) => {
        const data = row.data()
        postNavigate(`/sms/${data.id}/unlock`)
      }
    }] : []]
  })
})
