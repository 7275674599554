'use strict'
const $ = require('jquery')
const { setKpiValues } = require('../kpi')
const echarts = require('echarts')

// Due to an annoying property of Babel, we cannot mix "async" and CommonJS exporting style in the same file
// Therefore, we have to use ES6 module exporting style
export default class Graph {
  constructor (graph, statsParams) {
    Object.assign(this, graph)
    if (!this.viewParams) this.viewParams = {}
    this.statsParams = statsParams // This object is normally shared between all Graph instances
    this.$box = $(document.getElementById(this.id))
  }

  clear () {
    if (this.graph) this.graph.clear()
    if (this.kpi) setKpiValues({ kpi: this.id, prefix: this.prefix, suffix: this.suffix }) // Will set to zero (in case loading fails)
  }

  render () {
    if (!this.graph) this.initEChartsInstance()

    if (this.kpi) {
      setKpiValues(Object.assign({ kpi: this.id, prefix: this.prefix, suffix: this.suffix }, this.getKpiValues()))
    }

    this.graphOption = this.getChartConfig()
    this.graph.setOption(this.graphOption, true)
  }

  initEChartsInstance () {
    this.graph = echarts.init(this.$box.get(0))
  }

  updateView () {
    this.graphData = this.transformData(this.rawData)
    this.render()
  }

  async load () {
    this.clear()
    this.rawData = await this.loadData()
    this.graphData = this.transformData(this.rawData)
  }

  getAjaxParams () {
    const params = new window.URLSearchParams()
    params.set('domainId', this.statsParams.domainId)
    params.set('timeZone', Intl.DateTimeFormat().resolvedOptions().timeZone)
    return params
  }

  async loadData () {
    return $.ajax({ // async!
      url: `/statsData/${this.endpoint}?${this.getAjaxParams()}`,
      type: 'GET'
    })
  }

  transformData (rawData) {
    return rawData
  }

  // Guaranteed to be executed after all charts loaded their data
  getChartConfig () {
    return {
      title: {
        left: 'center',
        text: this.name,
        textStyle: {
          fontSize: 14,
          fontWeight: 'bold'
        }
      },
      toolbox: {
        showTitle: true,
        right: '8%',
        feature: {
          restore: {title: 'reset'},
          saveAsImage: {title: 'download'}
        }
      },
      tooltip: {
        trigger: 'axis',
        formatter: `${this.prefix || ''}{c}${this.suffix || ''}`
      }
    }
  }

  getKpiValues () {
    return {}
  }
}
