'use strict'

const $ = require('jquery')
const { encode } = require('./utils')

// Cache for claim prices (prefilled by main page template for pre-existing unclaimed SMS)
if (!window.claimPrices) window.claimPrices = {}

$(() => {
  if ($('#sms-table').length) {
    // This clunky mechanism is used because the websocket connection puts users into a "room" based on
    // user ID, however the claim price depends on the service price list which can be different based
    // on the domain the user is currently browsing. This context exists in a regular HTTP call, however.
    const getDeferredClaimPriceHtml = id => {
      if (window.claimPrices[id] != null) return encode(window.claimPrices[id].toFixed(2))

      if (window.claimPrices[id] === undefined) {
        window.claimPrices[id] = null // Fetching

        setTimeout(() => {
          $.getJSON(`/sms/${id}/unlockPrice`).done(data => {
            window.claimPrices[id] = data.price
            $(`.claim-price[data-sms-id="${id}"]`).text(data.price.toFixed(2))
          }).fail(() => {
            console.error(`Error getting claim price for SMS ${id}`)
            delete window.claimPrices[id]
            $(`.claim-price[data-sms-id="${id}"]`).text('?')
          })
        }, 50)

        return `<span class="claim-price" data-sms-id="${encode(id)}"><i class="fa fa-spinner fa-pulse"></i></span>`
      }
    }

    // wsConnection.js will also call this when a statusUpdate message is coming in through websockets
    window.onStatusUpdate = data => {
      lastUpdate = Date.now()

      if (data.reload) {
        if (!$('#loader').is(':visible')) { // A reload in the middle of number assignment can put the user on a page where they don't have a line
          window.location.href = window.location.href
        }
      } else {
        $('#status').text(data.status)
        if (data.status === 'Pending') {
          $('.pending-info').show()
        } else {
          $('.pending-info').hide()
        }
        if (data.status === 'Error' || data.status === 'Disconnected') {
          $('.error-info').show()
        } else {
          $('.error-info').hide()
        }

        // For the moment, we don't need to concern ourselves with deletions of single messages
        // But we don't want to replace the table every time because it will clear text selection
        if (!data.sms.length || !$(`#sms-table [data-id="${data.sms[0].id}"]`).length || !$(`#sms-table [data-id="${data.sms[data.sms.length - 1].id}"]`).length) {
          const $tbody = $('#sms-table tbody')
          $tbody.empty()
          for (const sms of data.sms) {
            const invalidServiceText = `${sms.text}
            <br>
            <br>
            Sorry! It looks like this message wasn't meant for you. But if you'd like to view the message, please
            <br>
            <form class="form-inline" action="/sms/${sms.id}/unlock" method="POST"><button class="btn btn-primary" type="submit">Claim Message (<i class="fa fa-diamond"></i> ${getDeferredClaimPriceHtml(sms.id)})</button></form>
            <br>
            If this is a mistake, please contact support for assistance.`
            const $tr = $(`<tr data-id="${sms.id}"/>`).appendTo($tbody)
            $('<td/>').text(new Date(sms.timestamp).toLocaleString()).appendTo($tr)
            $('<td/>').text(sms.phoneNumber).appendTo($tr)
            $('<td/>').html(!data.activeSmsValidation || sms.isValidService ? encode(sms.text) : invalidServiceText).appendTo($tr)
          }
        }
      }
    }

    let lastUpdate = 0
    setInterval(() => {
      if (window.ioConnection) {
        if (lastUpdate > Date.now() - 60000) return
        window.ioConnection.emit('requestStatusUpdate')
      } else {
        $.getJSON('/indexAjax').done(window.onStatusUpdate)
      }
      lastUpdate = Date.now()
    }, 15000)
  }
})
