'use strict'

// ES6-style import/export is used here since Babel doesn't allow us to mix "async" and CommonJS-style export syntax
import LineGraph from './LineGraph'

const $ = require('jquery')
const { getGraph } = require('../utils')
const moment = require('moment')
const Debouncer = require('../../Debouncer')
const { crc32 } = require('crc')

export default class CreditsUsedGraph extends LineGraph {
  constructor (graph, statsParams) {
    super(graph, statsParams)
    this.zoomDebouncer = new Debouncer(200) // We want the actions that we trigger on zoom fire only after 200ms of calmness
    this.visibleDateRange = { from: null, to: null }
  }

  getChartConfig () {
    const chartConfig = super.getChartConfig()

    if (this.includePieChart) {
      chartConfig.grid = { left: '5%', right: '56%' } // 55% would be the logical value, but then it visually appears a bit too wide to right

      chartConfig.series.push({
        name: 'Service Distribution',
        type: 'pie',
        id: 'pie',
        radius: '50%',
        center: ['75%', '55%'],
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c} ({d}%)'
        },
        data: [] // Is set dynamically later
      })
    }

    return chartConfig
  }

  async loadData () {
    if (!this.includePieChart) return super.loadData() // async

    // We need the service usage data as well because of the service distribution pie
    // We will load it in parallel to our own data, but only if there is no service bar graph
    // already loaded in the page (i.e. user is not admin). If there is a service bar graph,
    // that means that the same data is already being loaded by it, so we will later simply
    // "borrow" the data it already loaded, to avoid sending (and waiting for) a useless
    // duplicate request.
    const [rawData] = await Promise.all([
      super.loadData(), // loaded into rawData
      (async () => {
        if (!getGraph('serviceUsage')) {
          this.serviceUsageRawData = await $.ajax({
            url: `/statsData/serviceUsage?${this.getAjaxParams()}`,
            type: 'GET'
          })
        }
      })()
    ])
    return rawData
  }

  onZoomChange () {
    this.updateVisibleDateRange()
    this.updateServiceUsageGraphZoom()
    this.updateServiceDistributionPie()
  }

  initEChartsInstance () {
    super.initEChartsInstance()

    // Update service usage chart timeframe on zoom
    // Note: jQuery-style .on('datazoom reset') doesn't work
    const handler = event => {
      this.zoomDebouncer.debounce(() => {
        this.onZoomChange()
      })
    }
    this.graph.on('datazoom', handler)
    this.graph.on('restore', handler)
  }

  render () {
    super.render()

    this.onZoomChange()
  }

  updateVisibleDateRange () {
    // Currently, startValue and endValue are not returned in event: https://stackoverflow.com/a/43910746/1871033
    const { startValue, endValue } = this.graph.getOption().dataZoom[0]

    // We currently have the date strings as values, so we have to turn them into timestamps here
    this.visibleDateRange.from = moment(this.graphData.date[startValue], 'MMM DD YYYY').valueOf()
    this.visibleDateRange.to = moment(this.graphData.date[endValue], 'MMM DD YYYY').valueOf()
  }

  updateServiceUsageGraphZoom () {
    const serviceUsageGraph = getGraph('serviceUsage')
    if (serviceUsageGraph) {
      Object.assign(serviceUsageGraph.viewParams, this.visibleDateRange)
      serviceUsageGraph.updateView()
    }
  }

  updateServiceDistributionPie () {
    if (!this.includePieChart) return

    // We don't have any service_usages data from before its launch - avoid misleading display as "Not Service-Related"
    // and instead show as "Unknown" in that case
    //
    // It's also important that we count only the data from days after the launch for service usages, because otherwise
    // we will get a messy situation with a negative "not service-related" number that messes up the calculation forever
    // because on that day we will have only partial data from service usages, but no way to tell it apart because it's
    // already aggregated by day.
    //
    // This timestamp is the timestamp of the first recorded service_usage row in the production database.
    const SERVICE_USAGE_LAUNCH_TIMESTAMP = 1555943430093

    // We either loaded the data ourselves (because there was no service usage graph on the page)
    // or we can get it from the service usage chart.
    // Note: In case we loaded it ourselves, the missing days won't be filled in, but that's
    // fine for us here.
    const serviceUsageRawData = this.serviceUsageRawData || getGraph('serviceUsage').rawData

    const serviceData = {} // as {service: value}
    for (const [timestamp, row] of Object.entries(serviceUsageRawData.data)) {
      if (timestamp < this.visibleDateRange.from || timestamp > this.visibleDateRange.to || timestamp < SERVICE_USAGE_LAUNCH_TIMESTAMP) continue

      for (const [service, [nonRefundedValue]] of Object.entries(row)) {
        if (!serviceData[service]) serviceData[service] = 0
        serviceData[service] += nonRefundedValue
      }
    }

    // Get top 10 services (as [service, value])
    const TOP_SERVICES_AMOUNT = 10
    const topServices = Object.entries(serviceData).sort((a, b) => b[1] - a[1]).slice(0, TOP_SERVICES_AMOUNT)

    // Get totals, used for the "other service" and "not service-related" slices
    const totalOfTopServiceUsages = topServices.reduce((agg, curr) => agg + curr[1], 0)
    const totalOfAllServiceUsages = Object.values(serviceData).reduce((agg, curr) => agg + curr, 0)
    const totalCreditsUsed = Object.entries(this.rawData.data)
      .filter(([timestamp]) => timestamp >= this.visibleDateRange.from && timestamp <= this.visibleDateRange.to)
      .reduce((agg, curr) => agg + curr[1], 0)
    const totalUnknownCreditsUsed = Object.entries(this.rawData.data)
      .filter(([timestamp]) => timestamp >= this.visibleDateRange.from && timestamp <= this.visibleDateRange.to && timestamp < SERVICE_USAGE_LAUNCH_TIMESTAMP)
      .reduce((agg, curr) => agg + curr[1], 0)

    let pieData = [] // as {name, value} which seems to be what echarts expects for pies

    // Top 10
    for (const [service, value] of topServices) {
      const name = window.services[service] || service

      // Create a unique color for each slice to improve visibility and stability of the chart display among date range change
      // Color is generated based on hash of slice label
      const nameCrc = crc32(name)
      const part1 = nameCrc & 0xFF
      const part2 = (nameCrc >> 8) & 0xFF
      const part3 = (nameCrc >> 16) & 0xFF

      const color = `hsl(${[
        part1 * 360 / 256, // hue (0-360: full color spectrum)
        part2 * 30 / 256 + 40 + '%', // saturation (40%-70%)
        part3 * 40 / 256 + 30 + '%' // lightness (30%-70%)
      ].join(',')})`

      pieData.push({ name, value, itemStyle: { color } })
    }

    // Others
    // These get different shades of grey
    pieData.push({ name: 'Other Service', value: totalOfAllServiceUsages - totalOfTopServiceUsages, itemStyle: { color: '#888' } })
    pieData.push({ name: 'Not Service-Related', value: totalCreditsUsed - totalOfAllServiceUsages - totalUnknownCreditsUsed, itemStyle: { color: '#444' } }) // May in edge cases be negative, but echarts can still handle it
    pieData.push({ name: 'Unknown', value: totalUnknownCreditsUsed, itemStyle: { color: '#CCC' } })
    pieData = pieData.filter(({ value }) => value) // Remove empty slices

    this.graph.setOption({
      series: {
        id: 'pie',
        data: pieData
      }
    })
  }
}
