'use strict'

window.$ = window.jQuery = require('jquery')
window.Popper = require('popper.js')

require('bootstrap')
require('./dataTables')
require('typeahead.js')

require('./lineCountdown') // the proposal to call this "linalCountdown" (du du du duuuu du du du du du) was denied, SADLY...
require('./tableOfContents')
require('./wsConnection')
require('./activityPing')
require('./payment')
require('./tosConfirmation')
require('./updateNumber')
require('./lineForm')

require('../../svelteComponents')

require('bootstrap-select')
window.$.fn.selectpicker.Constructor.BootstrapVersion = '4'

require('./pageMixins')

// TODO: Replace these all-over-the-place conditionals with pageMixins

if (window.mainpage) { // from now on handle pagespecific scripts like this
  require('./smsUpdate')
  require('./serviceCheck')
}

if (window.userForm) {
  require('./userForm')
}

if (window.templateForm) {
  require('./templateForm')
}

if (window.statistics) {
  require('./statistics')
}
