'use strict'

// Agent lines table mixin
// Manages the table of lines in the agent line management page

const $ = require('jquery')
const { setupImprovedDataTable } = require('../dataTables')
const { encode, ellipsis } = require('../utils')

module.exports = ({ lineStatusColors }) => $(() => {
  setupImprovedDataTable($('#lineTable'), {
    columns: [
      { title: 'Line ID', data: 'id', render: (id, type, { changeable }) => `<span style="${changeable ? 'color: limegreen;' : ''}">${encode(id)}</span>`, defaultSort: 'asc' },
      { title: 'Number', data: 'number', defaultContent: '', render: (number, type, { accCheckLastWasOk, accCheckLastError, dealerType }) => `${encode(number)}${accCheckLastWasOk === 1 ? ' <i class="fa fa-circle text-success" title="Account OK"></i>' : ''}${accCheckLastWasOk === 0 ? ' <i class="fa fa-circle text-danger" title="Account Locked"></i>' : ''}${accCheckLastError ? ` <i class="fa fa-fa-exclamation-circle text-warning" title="Last account check failed: ${encode(accCheckLastError)}"></i>` : ''}<br><small>${dealerType}</small>` },
      {
        title: 'Status',
        data: 'augmentedStatus',
        defaultContent: '',
        render: (augmentedStatus, type, { error, generalError, changeStatus, nextScheduledChange, newNumber, scheduledSwitch }) => {
          const status = augmentedStatus.replace(/^.*?\//, '')
          return `<span style="color: ${lineStatusColors[status] || 'red'}">${encode(augmentedStatus)}</span>` +
            (changeStatus === 'pending' ? ` <i class="fa fa-refresh" title="New number waiting (${newNumber || 'UNKNOWN'})"></i>` : '') +
            (nextScheduledChange ? ` <i class="fa fa-clock-o" title="Change scheduled (${new Date(nextScheduledChange).toLocaleString()})"></i>` : '') +
            (scheduledSwitch ? ' <i class="fa fa-hourglass-half" style="color: black !important;" title="Switch-in scheduled"></i>' : '') +
            ((status !== 'Ready' && error) ? `<br><small title="${encode(error)}">${encode(ellipsis(error, 25))}</small>` : '') +
            (generalError ? `<br><small class="text-danger">${encode(generalError)}</span>` : '')
        }
      },
      {
        title: 'SIM ID',
        data: 'simId',
        className: 'break-word',
        defaultContent: '',
        render: (simId, type, { imei, provider, gatewayLocation }) => `${encode(simId)} ${encode(imei)} (${encode(provider)})<br /><small>${encode(gatewayLocation)}</small>`
      },
      { title: 'Comment', data: 'agentComment', className: 'break-word', defaultContent: '', render: agentComment => `<span title="${encode(agentComment || '')}">${encode(ellipsis(agentComment || '', 25))}</span>` },
      { title: 'Status', data: 'agentStatus', defaultContent: '' },
      { title: 'Activation', data: 'activationDate', defaultContent: '' }
    ],
    createdRow: (row, data, dataIndex) => {
      if (!data.isActive) $(row).addClass('text-muted')
    },
    serverSide: true,
    ajax: '/agentLineManagement/dtAjax',
    buttons: [$.fn.dataTable.defaults.buttons, {
      text: '$cog',
      tooltip: 'Manage',
      href: data => `/agentLineManagement/${data.id}`,
      className: 'btn-primary',
      extend: 'selected',
      isRowClickHandler: true
    }, {
      text: '$play',
      tooltip: 'Switch in ASAP',
      href: data => `/agentLineManagement/${data.id}/switchInAsap`,
      className: 'btn-warning',
      extend: 'selected',
      disabled: data => data.isActive || data.scheduledSwitch
    }]
  })

  function updateDealerTypeFilter () {
    const dealerType = $('#dealerType').val()
    $('[data-dealer-type]').show()
    if (dealerType) $('[data-dealer-type]').not(`[data-dealer-type="${dealerType}"]`).hide()
  }

  $('#dealerType').on('change', updateDealerTypeFilter)
  updateDealerTypeFilter()
})
