
function customPaymentCalc (amount, offers, price) {
  if (!offers.length) throw new Error('No offers for calculating price')

  let prev, next
  for (const offer of offers) {
    if ((amount && offer.amount <= amount) || (price && offer.price <= price)) {
      prev = offer
    } else if ((amount && offer.amount > amount) || (price && offer.price > price)) {
      next = offer
      break
    }
  }

  if (!prev) prev = { amount: 0, price: 0 }
  if (!next) next = { amount: 0, price: 0 }
  let offerPrice
  let offerAmount
  if (amount) offerPrice = prev.price + (next.price - prev.price) / (next.amount - prev.amount) * (amount - prev.amount)
  if (price) offerAmount = prev.amount + (next.amount - prev.amount) / (next.price - prev.price) * (price - prev.price)

  price = (price || offerPrice || 0)
  amount = (amount || Math.round(offerAmount) || 0)

  return {
    price: Number(price.toFixed(2)),
    singlePrice: price / amount,
    percent: getDiscountPercentage(offers[0], {price, amount}),
    amount: amount,
    fullString: amount + '=' + (price / amount)
  }
}

function getDiscountPercentage (baseOffer, offer) {
  return Math.floor(((offer.price / offer.amount) - (baseOffer.price / baseOffer.amount)) * 100 / baseOffer.price)
}

function addDiscountPercentToOffers (offers) {
  const base = offers[0]
  offers = offers.map((x, i) => {
    x.percent = Math.floor(((x.price / x.amount) - base.price) * 100 / base.price)
    return x
  })
  return offers
}

function normalizeOffers (offersString) {
  return offersString
    .split(/\r?\n/)
    .filter(x => !!x)
    .map(x => x.trim().split(/\s*=\s*/))
    .map(([amount, singlePrice]) => ({amount: Number(amount), price: Number((Number(amount) * Number(singlePrice)).toFixed(2)), singlePrice: Number(singlePrice), fullString: `${amount}=${singlePrice}`}))
    .sort((a, b) => a.amount - b.amount)
}

function parsePaymentMethodMarkupData (str) {
  const valueObject = {}
  for (const [k, v] of (str || '').split('\n').map(x => x.trim()).filter(x => x).map(x => x.split('=').map(x => x.trim()))) {
    valueObject[k.replace(/[^\w.-]/g, '')] = Number(v) || 0 // replace for XSS protection
  }
  return valueObject
}

function adjustMoneyAmountByMarkup (amount, markupPercent, reverse = false) {
  if (reverse) {
    amount = amount / (1 + markupPercent / 100)
    amount = Math.floor((amount * 100).toPrecision(10)) / 100
  } else {
    amount = amount * (1 + markupPercent / 100)
    amount = Math.ceil((amount * 100).toPrecision(10)) / 100
  }
  return amount
}

function adjustOfferByMarkup (offer, markupPercent, reverse = false) {
  offer = Object.assign({}, offer)
  offer.price = Number(adjustMoneyAmountByMarkup(offer.price, markupPercent, reverse).toFixed(2))
  offer.singlePrice = offer.singlePrice == null ? null : offer.price / offer.amount
  offer.fullString = offer.fullString == null ? null : offer.amount + '=' + offer.singlePrice
  return offer
}

function getUsdTopUpOffer (usdAmount) {
  return {
    price: Number(usdAmount.toFixed(2)),
    singlePrice: null, // This is infinite
    percent: 0,
    amount: 0,
    fullString: null // This is useless
  }
}

module.exports = {customPaymentCalc, addDiscountPercentToOffers, normalizeOffers, parsePaymentMethodMarkupData, adjustMoneyAmountByMarkup, adjustOfferByMarkup, getUsdTopUpOffer}
