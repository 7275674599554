'use strict'

// Agent BG change rules table mixin

const $ = require('jquery')
const { encode } = require('../utils')

module.exports = () => $(() => {
  let $editor

  const getNextId = () => Number($editor.find('tbody tr:last-child').attr('data-id') || 0) + 1

  const buildRow = (id = getNextId(), {simIdMatch = '', minInterval = 0, maxChgPerTimeframe = 0, timeframe = 0, changeAsap = false, disabled = false, disabledTimeframe = ''} = {}) => `
    <tr data-id="${id}">
      <td><input class="simIdMatch-field form-control" value="${encode(simIdMatch)}" placeholder="SIM ID match"/></td>
      <td><input class="minInterval-field form-control" value="${encode(minInterval)}" placeholder="Min. interval (h)"/></td>
      <td><input class="maxChgPerTimeframe-field form-control" value="${encode(maxChgPerTimeframe)}" placeholder="Max. chg. per timeframe"/></td>
      <td><input class="timeframe-field form-control" value="${encode(timeframe)}" placeholder="Timeframe (h)"/></td>
      <td><label><input class="changeAsap-field" value="1" type="checkbox" ${changeAsap ? 'checked' : ''}/> Change ASAP</label></td>
      <td><label><input class="disabled-field" value="1" type="checkbox" ${disabled ? 'checked' : ''}/> Disable BG Change</label></td>
      <td><input class="disabledTimeframe-field form-control" value="${encode(disabledTimeframe)}" placeholder="Wkds & hrs"/></td>
      <td style="white-space: nowrap;">
        <button type="button" title="Remove" class="remove-btn btn btn-outline-danger"><i class="fa fa-minus"></i></button>
        <button type="button" title="Add" class="add-btn btn btn-outline-info"><i class="fa fa-plus"></i></button>
      </td>
    </tr>
  `

  const agentBgChangeRules = JSON.parse($('#agentBgChangeRules').val())

  $editor = $(`
    <div class="editor clearfix">
      <table class="table">
        <thead>
          <tr>
            <th>SIM ID match</th>
            <th>Min. interval (h)</th>
            <th>Max. chg. per timeframe</th>
            <th>Timeframe (h)</th>
            <th>Change ASAP?</th>
            <th>Disable BG Change?</th>
            <th>Disabled timeframe</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          ${agentBgChangeRules.map((row, id) => buildRow(id, row)).join('')}
        </tbody>
      </table>
      <button type="button" title="Add" class="pull-right add-btn btn btn-outline-info"><i class="fa fa-plus"></i> Add row</a>
    </div>
  `).insertAfter('#agentBgChangeRules')

  // Use a .on(..., 'selector') instead of a normal listener on the elements because buttons can
  // be generated on the fly
  $editor.on('click', '.add-btn', (e) => {
    const $row = $(e.target).closest('tr')
    if ($row.length) {
      $row.after(buildRow())
    } else {
      $editor.find('tbody').append(buildRow())
    }
    updateTable()
    return false
  })

  $editor.on('click', '.remove-btn', (e) => {
    $(e.target).closest('tr').remove()
    updateTable()
    return false
  })

  const updateTable = () => {
    const data = []
    for (const el of Array.from($editor.find('tbody tr'))) {
      const rule = {
        simIdMatch: $(el).find('.simIdMatch-field').val(),
        minInterval: Number($(el).find('.minInterval-field').val()) || 0,
        maxChgPerTimeframe: Number($(el).find('.maxChgPerTimeframe-field').val()) || 0,
        timeframe: Number($(el).find('.timeframe-field').val()) || 0,
        changeAsap: $(el).find('.changeAsap-field').prop('checked'),
        disabled: $(el).find('.disabled-field').prop('checked'),
        disabledTimeframe: $(el).find('.disabledTimeframe-field').val()
      }
      data.push(rule)
    }
    $('#agentBgChangeRules').val(JSON.stringify(data))
  }

  updateTable()
  $editor.on('input change', 'input', updateTable)
})
