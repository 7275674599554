'use strict'

// Delayed refund rules table mixin

const $ = require('jquery')
const { encode } = require('../utils')

module.exports = () => $(() => {
  let $editor

  const getNextId = () => Number($editor.find('tbody tr:last-child').attr('data-id') || 0) + 1

  const buildRow = (id = getNextId(), {percentage = '', refunds = '', delayDays = '', evenIfTrusted = false} = {}) => `
    <tr data-id="${id}">
      <td><input class="percentage-field form-control" value="${encode(percentage)}" placeholder="Min. %"/></td>
      <td><input class="refunds-field form-control" value="${encode(refunds)}" placeholder="Min. credits"/></td>
      <td><input class="delayDays-field form-control" value="${encode(delayDays)}" placeholder="Delay (d)"/></td>
      <td><label><input class="evenIfTrusted-field" value="1" type="checkbox" ${evenIfTrusted ? 'checked' : ''}/> Also for trusted users</label></td>
      <td style="white-space: nowrap;">
        <button type="button" title="Remove" class="remove-btn btn btn-outline-danger"><i class="fa fa-minus"></i></button>
        <button type="button" title="Add" class="add-btn btn btn-outline-info"><i class="fa fa-plus"></i></button>
      </td>
    </tr>
  `

  const delayedRefundRules = JSON.parse($('#delayedRefundRules').val())

  $editor = $(`
    <div class="editor clearfix">
      <table class="table">
        <thead>
          <tr>
            <th>Min. refund percentage</th>
            <th>Min. refund credits</th>
            <th>Delay (days)</th>
            <th>Also for trusted users</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          ${delayedRefundRules.map((row, id) => buildRow(id, row)).join('')}
        </tbody>
      </table>
      <button type="button" title="Add" class="pull-right add-btn btn btn-outline-info"><i class="fa fa-plus"></i> Add row</a>
    </div>
  `).insertAfter('#delayedRefundRules')

  // Use a .on(..., 'selector') instead of a normal listener on the elements because buttons can
  // be generated on the fly
  $editor.on('click', '.add-btn', (e) => {
    const $row = $(e.target).closest('tr')
    if ($row.length) {
      $row.after(buildRow())
    } else {
      $editor.find('tbody').append(buildRow())
    }
    updateTable()
    return false
  })

  $editor.on('click', '.remove-btn', (e) => {
    $(e.target).closest('tr').remove()
    updateTable()
    return false
  })

  const updateTable = () => {
    const data = []
    for (const el of Array.from($editor.find('tbody tr'))) {
      const rule = {
        percentage: Number($(el).find('.percentage-field').val()) || 0,
        refunds: Number($(el).find('.refunds-field').val()) || 0,
        delayDays: Number($(el).find('.delayDays-field').val()) || 0,
        evenIfTrusted: $(el).find('.evenIfTrusted-field').prop('checked')
      }
      if (rule.delayDays > 0) data.push(rule)
    }

    data.sort((a, b) => (a.percentage - b.percentage) || (a.refunds - b.refunds))

    $('#delayedRefundRules').val(JSON.stringify(data))
  }

  updateTable()
  $editor.on('input change', 'input', updateTable)
})
