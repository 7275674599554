const $ = require('jquery')
$(() => {
  if (window.mainpage) {
    $('#usecases').change(e => {
      if ($('#usecases.selectpicker').val().length !== 0) {
        $('#get-new-number').removeAttr('disabled')
      } else {
        $('#get-new-number').attr('disabled', 'disabled')
      }
    })

    $('#get-new-number').click(e => {
      if ($(this).prop('disabled')) return
      let zip = $('#zipcode').val()
      if (!zip) zip = null

      const usecases = $('#usecases.selectpicker').val()
      if (!usecases) {
        $('#clientSideErrorMessage').text('invalid usage.')
        $('#clientSideError').show()
        return false
      }

      const $loader = $('#loader')
      $loader.fadeIn()
    })
  }
})
