<script>
  import ServiceTierOptionsTable from './ServiceTierOptionsTable.svelte'
  import ServicePricingTable from './ServicePricingTable.svelte'
  const { getListChanges, getUsecaseIdFromDisplayName, giveUsecasesActualIds } = require('../../../utils/usecasesTools')
  import { onDestroy } from 'svelte'

  export let hasDefault = true
  export let hasPreventNegative = true
  export let allowEmpty = false
  export let tiersSource
  export let defaultServiceTierSource
  export let usecasesSource
  export let usecases
  export let globalBasePricing
  export let serviceTierAssignmentsSource
  export let tutorial = true
  export let defaultServiceTier

  let tiers
  let serviceTierAssignments
  let accordionOpen = false

  const generateId = () => Math.random().toString(36).substring(2)

  function setDataFromFields () {
    tiers = []
    try {
      tiers = JSON.parse(window.$(tiersSource).val())
    } catch (e) {
      console.warn('Error loading JSON:', window.$(tiersSource).val())
      tiers = {}
    }

    tiers = Object.entries(tiers).map(([id, tier]) => ({
      id: id,
      name: tier.name,
      price: tier.price,
      preventNegative: tier.preventNegative === undefined ? true : tier.preventNegative
    }))


    try {
      serviceTierAssignments = JSON.parse(window.$(serviceTierAssignmentsSource).val())
    } catch (e) {
      console.warn('Error loading JSON:', window.$(serviceTierAssignmentsSource).val())
      serviceTierAssignments = {}
    }

    serviceTierAssignments = Object.entries(serviceTierAssignments).map(([serviceId, tierId]) => {
      const usecaseEntry = Object.entries(usecases).find(el => serviceId === el[1].nameBasedId)
      const tier = tiers.find(tier => tier.id === tierId)
      if(!usecaseEntry || !tier) return null
      return {
        serviceId: usecaseEntry[0],
        tierId
      }
    }).filter(el => !!el)

    if (hasDefault && defaultServiceTierSource) {
      try {
        defaultServiceTier = JSON.parse(window.$(defaultServiceTierSource).val())
      } catch (e) {
        console.warn('Error loading JSON:', window.$(defaultServiceTierSource).val())
        defaultServiceTier = {}
      }
    }
  }

  function updateSourceFields () {
    window.$(tiersSource).val(JSON.stringify(Object.fromEntries(tiers.map(({id, name, price, preventNegative, isDefaultTier}) => {
      if (name && price && !isDefaultTier) {
        return ([id, {price, preventNegative: !!preventNegative, name: name}])
      } else {
        return null
      }
    }).filter(el => !!el))))

    window.$(serviceTierAssignmentsSource).val(JSON.stringify(Object.fromEntries(serviceTierAssignments.map(el => {
      const tier = tiers.find(tier => el.tierId === tier.id)
      if (usecases[el.serviceId] && usecases[el.serviceId].nameBasedId && tier) {
        return [
          usecases[el.serviceId].nameBasedId,
          tier.id
        ]
      } else {
        return null
      }
    }).filter(el => !!el))))

    if (hasDefault) {
      let defaultTier = tiers.find(tier => tier.isDefaultTier) || defaultServiceTier
      window.$(defaultServiceTierSource).val(JSON.stringify({id: defaultTier.id, name: defaultTier.name, price: defaultTier.price, preventNegative: defaultTier.preventNegative}))
    }
  }

  // if anyone finds a better solution please replace!
  if (usecasesSource) {
    const changeHandler = () => {
      const newUsecasesObj = getUsecasesFromSource()
      const prevUsecases = Object.values(usecases).map(el => el.nameBasedId)
      const newUsecases = Object.keys(newUsecasesObj)
      const changes = getListChanges(prevUsecases, newUsecases)

      //handle renames
      const renamesByOldName = Object.fromEntries(changes.filter(c => c.type === 'renamed').map(({ value, newValue }) => [value, newValue]))
      for (const usecase of Object.values(usecases)) {
        if(!renamesByOldName[usecase.nameBasedId]) continue

        usecase.name = newUsecasesObj[renamesByOldName[usecase.nameBasedId]]
        usecase.nameBasedId = renamesByOldName[usecase.nameBasedId]
      }

      //handle additions and deletions
      for (const change of changes.filter(c => c.type !== 'renamed')) {
        if (change.type === 'added') {
          const newUsecaseId = Date.now() + Math.random().toString(36).substring(2)
          usecases[newUsecaseId] = {name: newUsecasesObj[change.value], nameBasedId: change.value}
        } else if (change.type === 'deleted') {
          delete usecases[Object.entries(usecases).find(el => el[1].nameBasedId === change.value)[0]]
        }
      }
      usecases = {...usecases}
      serviceTierAssignments = [...serviceTierAssignments]
    }

    const $field = window.$(usecasesSource)
    $field.on('change', changeHandler)
    onDestroy(() => {
      $field.off('change', changeHandler)
    })
  }

  function getUsecasesFromSource () {
    const newUsecases = {}
    window.$(usecasesSource).val().split(/\r?\n/).map(el => el.trim()).filter(el => el).forEach(el => newUsecases[getUsecaseIdFromDisplayName(el)] = el)
    return newUsecases
  }

  function removeDefaultTierAssignments () {
    serviceTierAssignments = serviceTierAssignments.map(el => el.tierId !== defaultServiceTier.id ? el : null).filter(el => !!el)
  }

  function changePreventNegative (event) {
    if(defaultServiceTier.preventNegative && !confirm('Changing this setting will margins make negative margins possible.\n\nChange at your own risk!')) {
      event.preventDefault()
    }
  }

  $: tiers, serviceTierAssignments, defaultServiceTier, updateSourceFields()
  $: defaultServiceTier, removeDefaultTierAssignments()

  usecases = giveUsecasesActualIds(usecasesSource ? getUsecasesFromSource() : usecases)
  setDataFromFields()

  if (defaultServiceTier.preventNegative === undefined) defaultServiceTier.preventNegative = true

</script>

<div>
  <div class="card">
    <div class="card-header d-flex p-0">
    <button type="button" class="btn btn-link" id="collapse-button" data-toggle="collapse" on:click={() => accordionOpen = !accordionOpen} data-target="#advancedCreditPricing" aria-expanded="true" aria-controls="advancedCreditPricing">
        <h4 class="mb-0 w-100 d-flex category-name">
          <div class="mr-auto">Advanced Service Cost Setup</div>
          {#if !accordionOpen}
          <i class="fa fa-caret-up"></i>
          {:else}
          <i class="fa fa-caret-down"></i>
          {/if}
        </h4>
      </button>
    </div>

    <div id="advancedCreditPricing" class="collapse" aria-labelledby="advancedCreditPricing">
      <div class="card-body">
        {#if tutorial}
          <p>Service tiers allow you to set your own cost for groups of services. Note that prices are restricted to a range of 0.01 <i class="fa fa-diamond"></i> to 99.99 <i class="fa fa-diamond"></i>.</p>
          <ol>
            <li>Define your service tiers. <span class="text-muted">Service tier names must be unique and will not be shown to end users.</span></li>
            <li>Set a default service cost (optional). <span class="text-muted">If not set, services without an assigned service tier will be using the global default service cost.</span></li>
            <li>Assign service tiers to services. <span class="text-muted">The global default price for a service is shown in the row as soon as you select a service.</span></li>
          </ol>
        {/if}

        {#if defaultServiceTier && !defaultServiceTierSource}
          <div class="form-group">
            <label for="defaultPrice">Default Service Cost <small>If not set, the global default service cost is used.</small></label>
            <div class="d-flex align-items-baseline">
              <div class="input-group">
                <input class="form-control" id="defaultPrice" min="0.01" max="99.99" step=".01" name="defaultPrice" value={ defaultServiceTier.price } type="number">
                <div class="input-group-append"><span class="input-group-text"><i class="fa fa-diamond"></i></span></div>
              </div>
              <div class="form-check ml-4" style="min-width: 200px">
                <input id="prevent-negative-global" type="checkbox" class="form-check-input" name="preventNegative" bind:checked={defaultServiceTier.preventNegative} on:click={event => changePreventNegative(event)}>
                <label class="form-check-label" for="prevent-negative-global">Prevent Negative Markup</label>
              </div>
            </div>
          </div>
        {/if}

        <ServiceTierOptionsTable {hasDefault} {allowEmpty} {hasPreventNegative} bind:tiers bind:defaultServiceTier/>
        <ServicePricingTable bind:tiers {usecases} {globalBasePricing} bind:serviceTierAssignments {hasPreventNegative}/>
      </div>
    </div>
  </div>
</div>

<style>
#collapse-button {
  width: 100%;
  display: flex;
}
</style>
