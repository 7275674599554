'use strict'

// Voice history table mixin
// Manages the table of voice calls on the service history page

const $ = require('jquery')
const { setupImprovedDataTable } = require('../dataTables')

// userId: ID of the user whose data is displayed
// ownerId: ID of the owner of the user whose data is displayed
module.exports = ({ userId, ownerId }) => $(() => {
  const isAdminOrOwner = window.loggedInUser.isAdmin || window.loggedInUser.id === ownerId

  setupImprovedDataTable($('#voice-table'), {
    columns: [
      { title: 'Requested at', data: 'timestamp', defaultSort: 'desc', render: $.fn.dataTable.render.date() },
      { title: 'Receiver', data: 'lineNumber', render: $.fn.dataTable.render.text() },
      { title: 'Status', data: 'dispStatus', render: dispStatus => `<span style="color: ${dispStatus === 'Error' ? 'red' : 'limegreen'};">${dispStatus}</span>` },
      { title: 'Refunded', data: 'refunded', render: refunded => refunded ? 'Yes' : 'No' }
    ],
    serverSide: true,
    ajax: `/services/${userId}/voice/dtAjax`,
    buttons: [$.fn.dataTable.defaults.buttons, ...isAdminOrOwner ? [{
      text: '$diamond',
      tooltip: 'Refund',
      href: data => `/services/refund/${data.id}`,
      className: 'btn-danger',
      extend: 'selected',
      disabled: data => data.refunded,
      action: (e, dt, node, button, row) => {
        if (window.loggedInUser.isAdmin) {
          return window.confirm('Are you sure?')
        } else {
          return window.confirm('Refunded credits will be subtracted from your account. Are you sure?')
        }
      }
    }] : []]
  })
})
