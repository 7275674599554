'use strict'

// Agent lines table mixin
// Manages the table of lines in the agent line management page

const $ = require('jquery')
const { setupImprovedDataTable } = require('../dataTables')
const { encode, ellipsis } = require('../utils')

module.exports = ({ lineStatusColors }) => $(() => {
  setupImprovedDataTable($('#lineTable'), {
    columns: [
      { title: 'Line ID', data: 'id', defaultSort: 'asc' },
      { title: 'Number', data: 'number', defaultContent: '', render: (number, type, { fakeNumber }) => `${encode(number)}` + (fakeNumber ? `<br><small class="text-danger"><strong>FAKE:</strong> ${encode(fakeNumber)}</small>` : '') },
      { title: 'Current User', data: 'username', className: 'break-word', defaultContent: '', render: (username, type, { userId }) => userId ? `<a href="/transactions/${userId}" title="${encode(username)}">${encode(ellipsis(username, 30))}</a>` : '' },
      { title: 'Current Service', data: 'currentServiceString', className: 'break-word', defaultContent: '', render: currentServiceString => `<span title="${encode(currentServiceString)}">${encode(ellipsis(currentServiceString, 30))}</span>` },
      {
        title: 'Status',
        data: 'augmentedStatus',
        defaultContent: '',
        render: (augmentedStatus, type, { scheduledSwitch }) => {
          const status = augmentedStatus.replace(/^.*?\//, '')
          return `<span style="color: ${lineStatusColors[status] || 'red'}">${encode(augmentedStatus)}</span>` + (scheduledSwitch ? ' <i class="fa fa-hourglass-half" style="color: black !important;" title="Switch-in scheduled"></i>' : '')
        }
      }
    ],
    createdRow: (row, data, dataIndex) => {
      if (!data.isActive) $(row).addClass('text-muted')
    },
    serverSide: true,
    ajax: '/csRepLineManagement/dtAjax',
    customAjaxDataHook: data => {
      data.extraSearch = { hideNotInserted: $('#hide-not-inserted-sims-checkbox').prop('checked') }
    },
    buttons: [$.fn.dataTable.defaults.buttons, {
      text: '$book',
      tooltip: 'View SMS',
      href: data => `/csRepLineManagement/${data.id}/viewSms`,
      className: 'btn-warning',
      extend: 'selected',
      isRowClickHandler: true
    }, {
      text: '$play',
      tooltip: 'Switch in ASAP',
      href: data => `/csRepLineManagement/${data.id}/switchInAsap`,
      className: 'btn-warning',
      extend: 'selected',
      disabled: data => data.isActive || data.scheduledSwitch
    }, {
      text: ' Hide Not-Inserted SIMs',
      className: 'hide-not-inserted-sims mb-0',
      tag: 'label',
      init: (dt, node, config) => {
        node.attr('for', 'hide-not-inserted-sims-checkbox')
        const $checkbox = $('<input>')
        $checkbox
          .attr('type', 'checkbox')
          .attr('id', 'hide-not-inserted-sims-checkbox')
          .css({'pointerEvents': 'none'})
          .prop('checked', true)
        node.prepend($checkbox)
      },
      action: (e, dt, node, config) => {
        $('#hide-not-inserted-sims-checkbox').prop('checked', !$('#hide-not-inserted-sims-checkbox').prop('checked'))
        dt.ajax.reload()
      }
    }]
  })
})
