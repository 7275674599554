'use strict'

const $ = require('jquery')

// HTML encoding function
// If preserveNewlines is set, it will turn newlines into <br>
// If replaceIcons is set, it will convert $xxx into a fa-xxx icon
function encode (text, preserveNewlines = false, replaceIcons = false) {
  if (preserveNewlines) text = text.replace(/\n/g, '\u2424')
  text = $('<span/>').text(text).html()
  if (preserveNewlines) text = text.replace(/\u2424/g, '<br>')
  if (replaceIcons) text = text.replace(/\$([a-z][a-z0-9-]*)\b/gi, (_, icon) => `<i class="fa fa-fw fa-${icon}"></i>`)
  return text
}

// Cuts off a string if it's too long, adding an ellipsis instead of the last character
function ellipsis (text, maxLength) {
  text = String(text)
  if (text.length > maxLength) {
    return text.substr(0, maxLength - 1) + '…'
  } else {
    return text
  }
}

// Navigates to a new page using a POST request
function postNavigate (url, params = {}) {
  const $form = $('<form style="display: none;" method="post"/>')
  $form.attr('action', url)
  for (const [name, value] of Object.entries(params)) {
    const $input = $('<input type="hidden"/>').appendTo($form)
    $input.attr('name', name)
    $input.attr('value', value)
  }
  $form.appendTo('body')
  $form.submit()
  $form.remove()
}

// can be used inside svelte components to convert string parameters from outside svelte into their respective types
function convertStringToDataType (str, type) {
  let convertedProp
  if (typeof str !== 'string') {
    convertedProp = str
  } else {
    switch (type) {
      case 'string':
        convertedProp = str
        break
      case 'object':
      case 'array':
        convertedProp = JSON.parse(str)
        break
      case 'boolean':
        convertedProp = str === 'true'
        break
      case 'number':
        convertedProp = Number(str)
        break
      default:
        throw new Error(`Typing ${type} of ${str} unknown. Unable to convert string to ${type}`)
    }
  }
  return convertedProp
}

function linkify (element) {
  if (!element || !(element instanceof window.HTMLElement)) {
    throw new Error('Invalid HTMLElement provided')
  }

  const urlPattern = new RegExp('\\bhttps?:\\/\\/[^\\s)]+(?<![)\\]},.;:!?"\\\'])', 'gi')

  function createLink (url) {
    const a = document.createElement('a')
    a.href = url
    a.textContent = url
    a.target = '_blank'
    a.rel = 'noopener noreferrer'
    return a
  }

  function traverse (node) {
    if (node.nodeType === window.Node.TEXT_NODE) {
      const text = node.textContent
      if (text.match(urlPattern)) {
        const fragment = document.createDocumentFragment()
        let lastIndex = 0
        let match

        urlPattern.lastIndex = 0
        while ((match = urlPattern.exec(text)) !== null) {
          const url = match[0]
          if (match.index > lastIndex) {
            fragment.appendChild(document.createTextNode(text.slice(lastIndex, match.index)))
          }
          fragment.appendChild(createLink(url))
          lastIndex = urlPattern.lastIndex
        }

        if (lastIndex < text.length) {
          fragment.appendChild(document.createTextNode(text.slice(lastIndex)))
        }

        node.parentNode.replaceChild(fragment, node)
      }
    } else if (node.nodeType === window.Node.ELEMENT_NODE && node.nodeName !== 'A') {
      for (const child of Array.from(node.childNodes)) {
        traverse(child)
      }
    }
  }

  traverse(element)
}

module.exports = { encode, ellipsis, postNavigate, convertStringToDataType, linkify }
