'use strict'

// Service Cost Preview Table

const $ = require('jquery')
const { encode } = require('../utils')
const { getUsecaseIdFromDisplayName } = require('../../../../utils/usecasesTools')

module.exports = ({ isSettingsPage = false } = {}) => $(() => {
  $(document).on('click', '.show-service-cost-table', e => {
    const usecases = isSettingsPage
      ? Object.fromEntries($('[name=usecases]').val().split('\n').map(x => x.trim()).filter(x => x).map(x => [getUsecaseIdFromDisplayName(x), x]))
      : window.usecases
    const globalServiceTierOptions = isSettingsPage
      ? JSON.parse($('[name=serviceTierOptions]').val())
      : window.globalServiceTierOptions
    const globalDefaultServiceTier = isSettingsPage
      ? JSON.parse($('[name=defaultServiceTier]').val())
      : window.globalDefaultServiceTier
    const globalServicePricing = isSettingsPage
      ? JSON.parse($('[name=serviceTiers]').val())
      : window.globalServicePricing
    const basicServiceMargins = isSettingsPage
      ? {}
      : JSON.parse($('[name=basicServiceMargins]').val())
    const defaultPrice = isSettingsPage
      ? null
      : ($('[name=defaultPrice]').val() ? Number($('[name=defaultPrice]').val()) : null)
    const preventNegative = isSettingsPage
      ? false
      : $('[name=preventNegative]').prop('checked')
    const serviceTierOptions = isSettingsPage
      ? {}
      : JSON.parse($('[name=serviceTierOptions]').val())
    const servicePricing = isSettingsPage
      ? {}
      : JSON.parse($('[name=serviceTiers]').val())

    const data = Object.entries(usecases).map(([usecase, displayName]) => {
      const systemTierId = globalServicePricing[usecase] || globalDefaultServiceTier.id
      const systemTierData = globalServiceTierOptions[systemTierId || ''] || globalDefaultServiceTier

      const values = {
        usecase,
        displayName,
        systemTierName: systemTierData.name,
        basePrice: systemTierData.price,
        markup: 0,
        originalMarkup: undefined,
        tierName: systemTierData.name,
        isSystemTier: true
      }

      if (servicePricing[usecase]) {
        const serviceTierId = servicePricing[usecase]
        const serviceTierData = serviceTierOptions[serviceTierId]
        values.tierName = serviceTierData.name
        values.isSystemTier = false

        if (serviceTierData.preventNegative && serviceTierData.price < values.basePrice) {
          values.originalMarkup = serviceTierData.price - values.basePrice
          values.markup = 0
        } else {
          values.markup = serviceTierData.price - systemTierData.price
        }
      } else if (defaultPrice) {
        values.tierName = 'Custom Default'
        values.isSystemTier = false

        if (preventNegative && defaultPrice < values.basePrice) {
          values.originalMarkup = defaultPrice - values.basePrice
          values.markup = 0
        } else {
          values.markup = defaultPrice - values.basePrice
        }
      } else {
        values.markup = basicServiceMargins[systemTierId || systemTierData.name] || 0
      }

      values.totalPrice = values.basePrice + values.markup

      return values
    })

    const $modal = $(`
      <div class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Preview Service Cost List</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <table class="table table-striped" style="font-size: 75%;">
                <thead>
                  <tr>
                    <th width="280px;">Service</th>
                    <th width="280px;">Tier</th>
                    <th width="100px;" align="right">Base Cost</th>
                    <th width="140px;" align="right">Markup</th>
                    <th width="140px;" align="right">Total Service Cost</th>
                  </tr>
                </thead>
                <tbody>
                  ${data.map(values => `
                    <tr>
                      <td>${encode(values.displayName)}</td>
                      <td>
                        <i class="fa fa-fw fa-${values.isSystemTier ? 'star' : 'user'}" title="${values.isSystemTier ? 'System Tier' : 'Custom Tier'}"></i>
                        ${encode(values.tierName)}${values.isSystemTier ? '' : ` <small class="text-muted">(instead of <i class="fa fa-star" title="System Tier"></i> ${encode(values.systemTierName)})</small>`}
                      </td>
                      <td align="right" data-order="${values.basePrice}">
                        <i class="fa fa-diamond"></i> ${values.basePrice.toFixed(2)}
                      </td>
                      <td align="right" ${values.markup < 0 ? 'class="text-danger"' : values.markup > 0 ? 'class="text-success"' : ''} data-order="${values.markup.toFixed(2)}">
                        <i class="fa fa-diamond"></i> ${values.originalMarkup !== undefined ? ` <span class="text-muted strikethrough">${values.originalMarkup.toFixed(2)}</span> ` : ''}${values.markup.toFixed(2)}
                      </td>
                      <td align="right" data-order="${values.totalPrice.toFixed(2)}">
                        <i class="fa fa-diamond"></i> ${values.originalMarkup !== undefined ? ` <span class="text-muted strikethrough">${(values.basePrice + values.originalMarkup).toFixed(2)}</span> ` : ''}${values.totalPrice.toFixed(2)}
                      </td>
                    </tr>
                  `).join('')}
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    `).appendTo('body')

    const $table = $modal.find('table')

    $table.DataTable({
      order: [[0, 'asc']] // Service
    })

    $modal.on('hidden.bs.modal', () => {
      $table.DataTable().destroy(true)
      $modal.remove()
    })

    $modal.modal('show')
  })
})
