<script>
  export let hasDefault = true
  export let hasPreventNegative = true
  export let tiers = []
  export let allowEmpty = false
  export let defaultServiceTier = {price: 1}


  function generateRandomTierId () {
    return Date.now() + Math.random().toString(36).substring(2)
  }

  function createNewTier () {
    return {name: '', isDefaultTier: false, id: generateRandomTierId(), preventNegative: true}
  }

  if (hasDefault) {
    if (!defaultServiceTier) defaultServiceTier = {price: 1}
    tiers.push({name: 'default', isDefaultTier: true, id: generateRandomTierId(), ...defaultServiceTier})
  }

  function createTableRow (idx) {
    tiers = tiers.toSpliced(idx, 0, createNewTier())
  }

  function removeTableRow (idx, isDefaultTier) {
    if (isDefaultTier) {
      return
    }
    tiers = tiers.toSpliced(idx, 1)
  }

  function changeDefaultTier (id, event) {
    if (tiers.find(el => el.id === id).isDefaultTier) return

    if(confirm('Changing your default service tier will remove all service assignments that used this tier from the list.\n\nAre you sure?')) {
      tiers = tiers.map((el, idx) => {
        const tier = {...el, isDefaultTier: el.id === id}
        if (el.id === id) defaultServiceTier = tier
        return tier
      })
    } else {
      event.preventDefault()
    }
  }

  function checkforProblems () {
    const nameList = {}
    for (const tier of tiers) {
      const name = tier.name
      if (nameList[name]) {
        [nameList[name], tier].forEach( tier => tier.hasWarning = true)
      } else {
        tier.hasWarning = false
        nameList[name] = tier
      }
    }
  }

  function changePreventNegative (event, preventNegative) {
    if(preventNegative && !confirm('Changing this setting will margins make negative margins possible.\n\nChange at your own risk!')) {
      event.preventDefault()
    }
  }

  $: tiers, checkforProblems()
</script>


<h3>Service Tiers</h3>
<small class="form-help text-muted">Service tiers define how many credits it takes to rent a service. No two tiers can have the same name.</small>

<div class="service-tier-options-editor clearfix">
  <table class="table">
    <thead>
      <tr>
        <th>Service Tier</th>
        <th>Price</th>
        {#if hasPreventNegative}
          <th>Prevent Negative Markups</th>
        {/if}
        {#if hasDefault}
          <th>Default</th>
        {/if}
        <th style="width: 120px"></th>
      </tr>
    </thead>
    <tbody>
      {#each tiers as {name: name = '', price: price = '', id, hasWarning: hasWarning = false, isDefaultTier, preventNegative}, i (id)}
        <tr class:default = {isDefaultTier} data-id="{id}">
          <td class:bg-warning={hasWarning}><input required class="tier-field form-control text-right " bind:value={name}/></td>
          <td><div class="input-group">
            <input type="number" required min="0.01" max="99.99" step=".01" class="price-field form-control" bind:value={price}/>
            <div class="input-group-append"><span class="input-group-text"><i class="fa fa-diamond"></i></span></div>
          </div></td>
          {#if hasPreventNegative}
            <td>
              <label><input class="negative-tier-field" type="checkbox" bind:checked={preventNegative} on:click={event => changePreventNegative(event, preventNegative)}/> Prevent Negative Markup</label>
            </td>
          {/if}
          {#if hasDefault}
            <td class="d-flex">
              <label><input class="default-tier-field" name="defaultServiceTierRadio" bind:value={name} type="radio" checked={isDefaultTier} on:click={event => changeDefaultTier(id, event)}/> Default Tier </label>
            </td>
          {/if}
          <td>
            <button type="button" title="Add" class="pull-right add-btn btn btn-outline-info" value={id} on:click={() => createTableRow(i)}><i class="fa fa-plus"></i></button>
            <button type="button" title="Remove" disabled={isDefaultTier || !allowEmpty && tiers.length === 1} class="remove-btn btn btn-outline-danger" on:click={() => removeTableRow(i, isDefaultTier)}><i class="fa fa-minus"></i></button>
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
  <button type="button" title="Add" class="pull-right add-btn btn btn-outline-info" on:click={ event => tiers = [...tiers, createNewTier()] }><i class="fa fa-plus"></i> Add row</button>
</div>



<style>

</style>
